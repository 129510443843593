import React, { useState } from "react"
import { graphql } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import {
    IntroHolder,
    TwoColumns,
    Content,
    Sidebar,
    NormalQuote
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../components/InnerPage';

export default ({ data }) => {
    const { city, email, use_email, website, use_website } = data.lokaleGemeindenCsv
    
    return (
        <InnerPage
            title={`Die Bahá’í-Gemeinde in ${city}`}
        >
            <IntroHolder
                title={city}
                subtitle="Die Bahá’í-Gemeinde in"
                showSubtitleBefore={true}
                breadcrumbs={{
                    'parent': 'Lokale Gemeinden',
                    'current-item': city,
                }}
            />
            <TwoColumns>
                <Content>
                    <div className="local-community-contact-details">
                        <h2>Kontaktdaten</h2>
                        <ul>
                        {use_email==="yes" ? <li>{email}</li> : false}
                        {use_website==="yes" ? <li className="external"><OutboundLink target="_blank" href={website}>Webseite</OutboundLink></li> : false}
                        </ul>
                    </div>
                </Content>
                <Sidebar showContactWidget={false}>
                    <NormalQuote reference="Bahá’u’lláh">
                        So mächtig ist das Licht der Einheit, daß es die ganze Erde erleuchten kann.
                    </NormalQuote>
                </Sidebar>
            </TwoColumns>
        </InnerPage>
    )
}

export const query = graphql`
    query ($slug: String!) {
        lokaleGemeindenCsv(fields: {slug: {eq: $slug}}) {
            city
            state
            website
            use_website
            email
            use_email
            facebook
            fields {
                slug
            }
        }
    }
`

